import React, { useEffect } from "react";
import { StyledGrid, StyledGridCol } from "../utils/StyledComponents";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { Alert } from "@abyss/web/ui/Alert";
import { Label } from "@abyss/web/ui/Label/Label";
import { Grid } from "@abyss/web/ui/Grid";
import { Text } from "@abyss/web/ui/Text";
import { useLocation, useNavigate } from "react-router-dom";
import PrintButton from "../utils/PrintButton";
import useDocumentTitle from "../utils/useDocumentTitle";
import { Link } from '@abyss/web/ui/Link';

const IndianaHospiceConfirmation = () => {
  useDocumentTitle("Indiana Hospice Confirmation");
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state?.data;
    useEffect(() => {
      if (!data) {
        navigate("/indianaHospiceForm");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <StyledGrid container>
    <StyledGrid section paddingYZero>
      <StyledGridCol span={11}  rowGapLg>
          <Heading offset={0}>Confirmation and Summary</Heading>
          </StyledGridCol>
          <PrintButton confirmation />
          <StyledGridCol span={{ xs: 12, sm: 12, md: 11, lg: 11, xl: 11 }}>
        <Alert
          title="Indiana hospice notification form submission complete"
          variant="success"
        >
          <Grid space="$sm">
            <StyledGridCol span={{ xs: 4, sm: 4, md: 12, lg: 12, xl: 12 }}>
            A UnitedHealthcare representative will be in contact with you, please allow up to 10 calendar days for response.
            </StyledGridCol>
            <StyledGridCol span={{ xs: 12, sm: 12, md: 11, lg: 11, xl: 11 }}>
           <span><b>Submission Id:</b>{" "}
              <Text size="$sm">{data?.submissionId}</Text> </span>
            </StyledGridCol>
            <StyledGridCol span={{ xs: 6, sm: 6, md: 8, lg: 8, xl: 8 }}>
             <span> <b>Contact Name: </b>
              <Text size="$sm" style={{ overflowWrap: "break-word" }}>
                {data?.providerName ? data.providerName : "-"}
              </Text></span>
            </StyledGridCol>
            <StyledGridCol span={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 8 }}>
             <span><b>Submission Date: </b> {" "}
              <Text size="$sm">{data?.submittedDate}</Text></span>
            </StyledGridCol>
          </Grid>
        </Alert>
      </StyledGridCol>
    </StyledGrid>

    <StyledGrid section>
        <StyledGridCol span={12} paddingYZero>
          <Heading offset={4}> Provider contact information</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Name</Label>
          <Text>{data?.providerName ? data.providerName : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Phone</Label>
          <Text>{data?.providerPhoneNumber ? data.providerPhoneNumber : "-"}</Text>
        </StyledGridCol>
    </StyledGrid>

    <StyledGrid section span={12} >
    <StyledGridCol span={12} paddingYZero>  
    <Heading offset={4}> Member information</Heading>
    </StyledGridCol>
    <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Full Name</Label>
          <Text>{data?.memberFullName ? data.memberFullName : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Medicaid ID</Label>
          <Text>{data?.memberMedicaidId ? data.memberMedicaidId : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={12}
          paddingYZero
          wordWrap
        >
          <Label size="md">Diagnosis code(s)</Label>
          <Text>{data?.diagnosisCodes ? data.diagnosisCodes : "-"}</Text>
        </StyledGridCol>
        
    </StyledGrid>

    <StyledGrid section span={12} >  
    <StyledGridCol span={12} paddingYZero>
          <Heading offset={4}>Additional comments</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Comments</Label>
          <Text>{data?.additionalComments ? data.additionalComments : "-"}</Text>
        </StyledGridCol>
    </StyledGrid>

    <StyledGrid section span={12} style={{marginBottom: '16px' }}>
        <StyledGridCol span={12} paddingYZero>
          <Heading offset={4}>Files uploaded</Heading>
        </StyledGridCol>
        <StyledGridCol
        rowGapMd
        span={12}
          paddingYZero
          wordWrap>
          {data?.files?.map((file, index) => {
            const filePath = URL.createObjectURL(file);
           return <Link  href = {filePath} openNewWindow key={index}>{file.name} </Link>
          })}
        </StyledGridCol>
    </StyledGrid>
    </StyledGrid>
  );
};

export default IndianaHospiceConfirmation;