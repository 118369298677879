import { useRef, useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const documentTitle = useRef(document.title);
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = documentTitle.current;
      }
    },
    [prevailOnUnmount]
  );
}

export default useDocumentTitle;
