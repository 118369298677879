import { PageHeader } from "@abyss/web/ui/PageHeader";
import { PageFooter } from "@abyss/web/ui/PageFooter";
import { ThemeProvider } from "@abyss/web/ui/ThemeProvider";
import { ToastProvider } from "@abyss/web/ui/Toast";
import { createTheme } from "@abyss/web/tools/theme";
import { routes as router, internalRoutes } from "./routes";
import { AuthProvider } from "react-oidc-context";
import { RouterProvider } from "react-router-dom";
import { OIDC_CONFIG } from "./Authentication/Constants";
const themeOverride = {
  theme: {
    fonts: { primary: "uhcsans", heading: "uhcserif" },
  },
};

const theme = createTheme("uhc", themeOverride);
function App() {
  const currentHost = window.location.host;
  const isInternal = currentHost.match(/internal/);
  return (
    <AuthProvider {...OIDC_CONFIG}>
      <ThemeProvider theme={theme}>
        <ToastProvider />
        <PageHeader fullWidth />
        <RouterProvider router={!isInternal ? router : internalRoutes} />
        <PageFooter subFooterTitle="Optum, Inc. All rights reserved."></PageFooter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
