import React, { useState } from "react";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import { useForm } from "@abyss/web/hooks/useForm";
import { FormProvider } from "@abyss/web/ui/FormProvider";
import { SelectInput } from "@abyss/web/ui/SelectInput";
import { TextInput } from "@abyss/web/ui/TextInput";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { TextInputArea } from "@abyss/web/ui/TextInputArea";
import { Button } from "@abyss/web/ui/Button";
import { DateInput } from "@abyss/web/ui/DateInput";
import { useToast } from "@abyss/web/hooks/useToast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useDocumentTitle from "../utils/useDocumentTitle";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {
  StyledGrid,
  StyledGridCol,
  RecaptchaComponent,
} from "../utils/StyledComponents";
import PrintButton from "../utils/PrintButton";
import {
  alphaNumericWithoutSpace as alphaNumericValidation,
  alphaValidation,
  re,
} from "../utils/ValidationPatterns";
dayjs.extend(isSameOrBefore);

export default function EDISupportForm() {
  useDocumentTitle("EDI Transaction Support Form");
  const [requiredFields, setRequiredFields] = useState([]);
  const defaultValues = {
    contactInfo: {
      contactName: "",
      phoneNo: "",
      email: "",
      taxId: "",
      billingProvNpi: "",
      completedBy: "",
    },
    product: "",
    function: "",
    issueDesc: "",
    memberInfo: {
      memberName: "",
      memberId: "",
      refNotificationNo: "",
      renderingProv: "",
      payerTraceNo: "",
      dateOfService: "",
      billedAmt: "",
      checkPaymentNo: "",
      renderingProvNpi: "",
      dateEdiTxnSubmitted: "",
      claimNo: "",
      payerId: "",
      clearingHouse: "",
      captcha: "",
    },
  };
  const navigate = useNavigate();
  const form = useForm({ ...defaultValues });
  const { toast } = useToast();
  const EDI_BASE_URI = "/api/ediForm";
  const dateValidation = () => {
    const date = new Date();
    date.setDate(date.getDate());
    return date;
  };

  const onFunctionChangeTrigger = (v) => {
    let fieldsToValidate = [];
    switch (v) {
      case "270/271 Eligibility Inquiries": {
        fieldsToValidate = [
          "memberInfo.memberName",
          "memberInfo.memberId",
          "memberInfo.dateEdiTxnSubmitted",
        ];
        break;
      }
      case "276/277 Claim Status Inquiries": {
        fieldsToValidate = [
          "memberInfo.memberName",
          "memberInfo.memberId",
          "memberInfo.dateOfService",
          "memberInfo.billedAmt",
          "memberInfo.dateEdiTxnSubmitted",
        ];
        break;
      }
      case "278 Notification Inquiries": {
        fieldsToValidate = [
          "memberInfo.memberName",
          "memberInfo.memberId",
          "memberInfo.dateEdiTxnSubmitted",
          "memberInfo.refNotificationNo",
        ];
        break;
      }
      case "835 Electronic Remittance Missing": {
        fieldsToValidate = [
          "memberInfo.memberName",
          "memberInfo.memberId",
          "memberInfo.dateOfService",
          "memberInfo.billedAmt",
          "memberInfo.checkPaymentNo",
          "memberInfo.dateEdiTxnSubmitted",
        ];
        break;
      }
      case "Claim not on file":
      case "Claim not received": {
        fieldsToValidate = [
          "memberInfo.memberName",
          "memberInfo.memberId",
          "memberInfo.dateOfService",
          "memberInfo.billedAmt",
          "memberInfo.dateEdiTxnSubmitted",
        ];
        break;
      }
      case "Claim rejected": {
        fieldsToValidate = [
          "memberInfo.memberName",
          "memberInfo.memberId",
          "memberInfo.dateOfService",
          "memberInfo.billedAmt",
          "memberInfo.dateEdiTxnSubmitted",
          "memberInfo.claimNo",
        ];
        break;
      }
      case "Claim Denied on EOB": {
        alert(
          "This form should only be used for questions regarding electronic claim submission rejections. Please contact the Customer Service number provided on your Explanation of Benefits for questions on claim denials on the EOB."
        );
        break;
      }
      default: {
        break;
      }
    }
    form.clearErrors([...requiredFields]);
    form.reset({
      ...form.getValues(),
      memberInfo: { ...defaultValues.memberInfo },
    });
    setRequiredFields([...fieldsToValidate]);
  };

  const onSubmit = async function (data) {
    try {
      const response = window.grecaptcha.getResponse();
      if (response.length === 0) {
        toast.show({
          id: "ReCaptcha Toast",
          withCloseButton: true,
          autoClose: 5000,
          title: "Action Required",
          message: "Recaptcha missing",
          icon: <IconMaterial icon="error" />,
          css: { "abyss-toast-container": { backgroundColor: "$error3" } },
        });
        return;
      }
      await axios({
        url: "/api/recaptcha",
        method: "GET",
        params: {
          verify: response,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const resp = await axios({
        url: EDI_BASE_URI,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
      if (resp.status === 200) {
        toast.show({
          id: "success-toast",
          autoClose: 5000,
          withCloseButton: true,
          title: "Success",
          icon: <IconMaterial icon="check_circle" />,
          css: { "abyss-toast-container": { backgroundColor: "$success1" } },
          message: "Mail Sent Successfully!",
        });
        navigate("/ediConfirmation", {
          state: { data: { ...data, caseNumber: resp.data.caseNumber } },
          replace: true,
        });
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((err) => {
          toast.show({
            id: err.field,
            withCloseButton: true,
            autoClose: 5000,
            title: `Validation Error: ${err.field}`,
            message: err.message,
            icon: <IconMaterial icon="error" />,
            css: { "abyss-toast-container": { backgroundColor: "$error3" } },
          });
        });
      }
    }
  };

  const CLAIM_DENIED_ON_EOB = "Claim Denied on EOB";

  const products = [
    { value: "Oxford", label: "Oxford" },
    { value: "NHP", label: "NHP" },
    { value: "UnitedHealthcare West", label: "UnitedHealthcare West" },
    {
      value: "UnitedHealthcare Community Plan",
      label: "UnitedHealthcare Community Plan",
    },
    {
      value: "UnitedHealthcare Commercial",
      label: "UnitedHealthcare Commercial",
    },
    {
      value: "UnitedHealthcare Medicare Solutions",
      label: "UnitedHealthcare Medicare Solutions",
    },
  ];

  const functions = [
    {
      value: "270/271 Eligibility Inquiries",
      label: "270/271 Eligibility Inquiries",
    },
    {
      value: "276/277 Claim Status Inquiries",
      label: "276/277 Claim Status Inquiries",
    },
    {
      value: "278 Notification Inquiries",
      label: "278 Notification Inquiries",
    },
    {
      value: "835 Electronic Remittance Inquiries",
      label: "835 Electronic Remittance Inquiries",
    },
    {
      value: "835 Electronic Remittance Missing",
      label: "835 Electronic Remittance Missing",
    },
    {
      value: "EFT Inquiries (UnitedHealthcare Community Plan)",
      label: "EFT Inquiries (UnitedHealthcare Community Plan)",
    },
    {
      value: "EFT Missing (UnitedHealthcare Community Plan)",
      label: "EFT Missing (UnitedHealthcare Community Plan)",
    },
    { value: "ERA/EFT", label: "ERA/EFT" },
    { value: "Claim Denied on EOB", label: "Claim Denied on EOB" },
    { value: "Claim not on file", label: "Claim not on file" },
    { value: "Claim not received", label: "Claim not received" },
    { value: "Claim rejected", label: "Claim rejected" },
    { value: "General EDI", label: "General EDI" },
  ];
  const completedBy = [
    {
      label: "Provider",
      value: "Provider",
    },
    {
      label: "UnitedHealthcare Employee",
      value: "UnitedHealthcare Employee",
    },
  ];

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <StyledGrid container>
        <StyledGrid section>
          <StyledGridCol span={11} rowGapSm paddingYZero>
            <Heading offset={2}>EDI Transaction Support Form</Heading>
            <Text size="$sm">
              This report should be used for EDI related issues only. Report
              suspected problems with electronic submissions through a clearing
              house. Use this for issues including: Provider/Billing or
              Clearinghouse receiving a rejection message, experiencing problems
              with the 837 or checking claim status and claims not on a file.
            </Text>
          </StyledGridCol>
          <PrintButton />
        </StyledGrid>
        {/* Contact Information */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}>Contact Information</Heading>
          </StyledGridCol>
          {/* Contact Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Contact Name (UHG Associate Contact)"
              model="contactInfo.contactName"
              isClearable
              maxLength={95}
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              validators={{
                required:
                  form.getValues("function") !== CLAIM_DENIED_ON_EOB
                    ? "Name is required"
                    : false,
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
              subText="Person reporting issue on behalf of the user."
              data-testid="contact-name-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Role of Contact */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <SelectInput
              label="Role of Contact"
              model="contactInfo.completedBy"
              options={completedBy}
              enableOutsideScroll
              isClearable
              validators={{
                required: "Role of Contact is required",
              }}
              data-testid="role-of-contact-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Phone */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              mask="phone"
              label="Phone"
              model="contactInfo.phoneNo"
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              isClearable
              inputLeftElement={
                <IconMaterial icon="phone" size="18px" color="$gray6" />
              }
              validators={{
                required:
                  form.getValues("function") !== CLAIM_DENIED_ON_EOB
                    ? "Phone is required"
                    : false,
                validate: (v) => {
                  const value = v;
                  const phoneNumber = value.replace(/\D/g, "");
                  if (
                    form.getValues("function") !== CLAIM_DENIED_ON_EOB &&
                    phoneNumber.length < 10
                  ) {
                    return "Phone number should have 10 digits";
                  }
                },
              }}
              autoComplete="tel-local"
              data-testid="phone-no-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Email */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Email"
              model="contactInfo.email"
              autoComplete="email"
              maxLength={45}
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              validators={{
                required:
                  form.getValues("function") !== CLAIM_DENIED_ON_EOB
                    ? "Email is required"
                    : false,
                pattern: {
                  value: re,
                  message: "Invalid Email",
                },
              }}
              isClearable
              inputLeftElement={
                <IconMaterial icon="mail_outline" size="18px" color="$gray6" />
              }
              data-testid="email-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Tax Identification Number */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              mask="numeric"
              label="Tax Identification Number"
              model="contactInfo.taxId"
              isClearable
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              maxLength={9}
              validators={{
                required:
                  form.getValues("function") !== CLAIM_DENIED_ON_EOB
                    ? "Tax Identification Number is required"
                    : false,
                minLength: {
                  value: 9,
                  message: "Tax Identification Number should have 9 digits",
                },
                maxLength: {
                  value: 9,
                  message: "Tax Indentification Number should have 9 digits",
                },
              }}
              data-testid="taxid-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Billing Provider NPI */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Billing Provider NPI"
              model="contactInfo.billingProvNpi"
              maxLength={10}
              mask="numeric"
              isClearable
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              validators={{
                minLength: {
                  value: 10,
                  message: "Billing Provider NPI should have 10 digits",
                },
                maxLength: {
                  value: 10,
                  message: "Billing Provider NPI should have 10 digits",
                },
              }}
              data-testid="npi-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>
        {/* Issue Details */}
        <StyledGrid section>
          {/* Header */}
          <StyledGridCol paddingYZero span={12}>
            <Heading offset={4}>Issue Details</Heading>
          </StyledGridCol>
          {/* Products */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <SelectInput
              label="Select a Product"
              model="product"
              options={products}
              validators={{
                required: "Product field is required",
              }}
              placeholder="Select a Product"
              enableOutsideScroll
              isClearable
              data-testid="product-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Functions */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <SelectInput
              label="Select a Function"
              model="function"
              options={functions}
              onChange={onFunctionChangeTrigger}
              placeholder="Select a Function"
              validators={{
                required: "Function field is required",
              }}
              enableOutsideScroll
              isClearable
              data-testid="function-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            paddingYZero
          >
            <TextInputArea
              style={{ marginTop: "12px" }}
              label="Provide a detailed explanation of the issue"
              model="issueDesc"
              maxLength={200}
              subText="Include the rejection message if available."
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              disableAutoSize
              validators={{
                required:
                  form.getValues("function") !== CLAIM_DENIED_ON_EOB
                    ? "Provide a detailed explanation of the issue"
                    : false,
              }}
              data-testid="issue-desc-field"
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>
        {/* Additional Information */}
        <StyledGrid section>
          {/* Header */}
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}>Additional Information</Heading>
            <Text size="$sm">
              If the issue is impacting more than one member, provide only one
              member as an example.
            </Text>
          </StyledGridCol>
          {/* Member Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Member Name"
              model="memberInfo.memberName"
              isClearable
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              maxLength={95}
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.memberName") >= 0
                    ? "Member Name is required"
                    : false,
                pattern: {
                  value: alphaValidation,
                  message:
                    "Member Name should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Date Of Service */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <DateInput
              label="Date of Service"
              model="memberInfo.dateOfService"
              mask="date"
              maximumDate={dateValidation()}
              subText=""
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              placeholder=""
              enableOutsideScroll
              isClearable
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.dateOfService") >= 0
                    ? "Date of Service is required"
                    : false,
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Date of EDI Transaction was Submitted */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <DateInput
              label="Date EDI Transaction was Submitted"
              model="memberInfo.dateEdiTxnSubmitted"
              maximumDate={dateValidation()}
              subText=""
              placeholder=""
              mask="date"
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              enableOutsideScroll
              isClearable
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.dateEdiTxnSubmitted") >= 0
                    ? "Date EDI Transaction was Submitted is required"
                    : false,
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Member ID*/}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Member ID"
              model="memberInfo.memberId"
              maxLength={30}
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              isClearable
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.memberId") >= 0
                    ? "Member ID is required"
                    : false,
                pattern: {
                  value: alphaNumericValidation,
                  message: "Member ID should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Billed Amount */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Billed Amount"
              mask="numeric"
              maxLength={10}
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              model="memberInfo.billedAmt"
              isClearable
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.billedAmt") >= 0
                    ? "Billed Amount is required"
                    : false,
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Claim Number */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Claim Number"
              mask="numeric"
              model="memberInfo.claimNo"
              maxLength={20}
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              isClearable
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.claimNo") >= 0
                    ? "Claim Number is required"
                    : false,
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Referring/Notification Number */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Referral/Notification Number"
              model="memberInfo.refNotificationNo"
              maxLength={30}
              isClearable
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.refNotificationNo") >= 0
                    ? "Referral/Notification Number is required"
                    : false,
                pattern: {
                  value: alphaNumericValidation,
                  message:
                    "Referral/Notification Number should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Check/Payment Number */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Check/Payment Number"
              model="memberInfo.checkPaymentNo"
              maxLength={30}
              isClearable
              isDisabled={form.getValues("function") === CLAIM_DENIED_ON_EOB}
              validators={{
                required:
                  form.getValues("funciton") !== CLAIM_DENIED_ON_EOB &&
                  requiredFields.indexOf("memberInfo.checkPaymentNo") >= 0
                    ? "Check/Payment Number is required"
                    : false,
                pattern: {
                  value: alphaNumericValidation,
                  message:
                    "Check/Payment Number should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Payer ID */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Payer ID"
              maxLength={30}
              model="memberInfo.payerId"
              isClearable
              validators={{
                pattern: {
                  value: alphaNumericValidation,
                  message: "Payer ID should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Rendering Provider */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Rendering Provider"
              maxLength={95}
              model="memberInfo.renderingProv"
              isClearable
              validators={{
                pattern: {
                  value: alphaValidation,
                  message:
                    "Rendering provider should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Rendering Provider NPI */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              mask="numeric"
              maxLength={10}
              label="Rendering Provider NPI"
              model="memberInfo.renderingProvNpi"
              isClearable
              validators={{
                minLength: {
                  value: 10,
                  message: "Rendering Provider NPI should have 10 digits",
                },
                maxLenght: {
                  value: 10,
                  message: "Rendering Provider NPI should have 10 digits",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Clearinghouse */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Clearinghouse"
              model="memberInfo.clearingHouse"
              maxLength={95}
              isClearable
              validators={{
                pattern: {
                  value: alphaValidation,
                  message:
                    "Clearinghouse should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          {/* Payment Trace*/}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
            paddingYZero
          >
            <TextInput
              label="Payer Trace Number"
              maxLength={30}
              model="memberInfo.payerTraceNo"
              isClearable
              validators={{
                pattern: {
                  value: alphaNumericValidation,
                  message:
                    "Payer Trace Number should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
            paddingYZero
          ></StyledGridCol>
        </StyledGrid>
        <RecaptchaComponent
          span={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
          paddingYZero
          model="captcha"
          className="g-recaptcha"
          validators={{ required: true }}
          data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        />
        <StyledGridCol style={{ width: "100px" }} paddingXZero paddingYZero>
          <Button size="$lg" type="submit">
            Submit
          </Button>
        </StyledGridCol>
      </StyledGrid>
    </FormProvider>
  );
}
