import { IconMaterial } from "@abyss/web/ui/IconMaterial/IconMaterial";
import { Text } from "@abyss/web/ui/Text";
import React from "react";
import { StyledGrid, StyledGridCol } from "./StyledComponents";

const PrintButton = (props) => {
  return (
    <StyledGrid section paddingYMd={props.confirmation}>
      <StyledGridCol
        style={{ width: "24px!important" }}
        paddingYZero
        paddingXsm
      >
        <IconMaterial icon="print" size="24px" onClick={() => window.print()} />
      </StyledGridCol>
      <StyledGridCol rowGapSm paddingYXs paddingXZero pointer>
        <Text
          size="$md"
          fontWeight={700}
          color="$accent1"
          onClick={() => window.print()}
        >
          {props.title ? props.title : "Print Form"}
        </Text>
      </StyledGridCol>
    </StyledGrid>
  );
};

export default PrintButton;
