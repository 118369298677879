import { Alert } from "@abyss/web/ui/Alert";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { Label } from "@abyss/web/ui/Label/Label";
import { Text } from "@abyss/web/ui/Text";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrintButton from "../utils/PrintButton";
import { StyledGrid, StyledGridCol } from "../utils/StyledComponents";
import useDocumentTitle from "../utils/useDocumentTitle";

const KansasProviderReferralConfirmation = () => {
  useDocumentTitle("Kansas Provider Referral Confirmation");
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  useEffect(() => {
    if (!data) {
      navigate("/kansasReferral");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledGrid container>
      <StyledGrid section paddingYZero>
        <StyledGridCol span={11} rowGapLg>
          <Heading offset={2}>Confirmation and Summary</Heading>
          <Alert
            title="Provider Care Coordination Referral Form Submission Complete"
            variant="success"
          >
            A UnitedHealthcare Representative will be in contact with you,
            please allow up to 10 calendar days for response.<br></br>
          </Alert>
        </StyledGridCol>
        <PrintButton confirmation />
      </StyledGrid>
      <StyledGrid section>
        <StyledGridCol span={12} paddingYZero>
          <Heading offset={4}> Referring Provider Information</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Name</Label>
          <Text>{data?.referringProName ? data.referringProName : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Tax Identification Number</Label>
          <Text>{data?.prvTaxId ? data.prvTaxId : "-"}</Text>
        </StyledGridCol>

        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">NPI</Label>
          <Text>{data?.prvNPI ? data.prvNPI : "-"}</Text>
        </StyledGridCol>
      </StyledGrid>
      <StyledGrid section>
        <StyledGridCol span={12} paddingYZero>
          <Heading offset={4}> Referring Provider Contact Information</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Name</Label>
          <Text>{data?.contactName ? data.contactName : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Phone</Label>
          <Text>
            {data?.refPrvContactPhone ? data.refPrvContactPhone : "-"}
          </Text>
        </StyledGridCol>
      </StyledGrid>
      <StyledGrid section>
        <StyledGridCol span={12}>
          <Heading offset={4}>Member Information</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Full Name</Label>
          <Text>{data?.memberName ? data.memberName : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Medicaid ID</Label>
          <Text>{data?.memberMedicaidId ? data.memberMedicaidId : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        ></StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Diagnosis or Significant Present Problems</Label>
          <Text>
            {data?.diagnoseOrSignificantInfo
              ? data.diagnoseOrSignificantInfo
              : "-"}
          </Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Primary Care Physician</Label>
          <Text>
            {data?.memberPrimaryCarePhysician
              ? data.memberPrimaryCarePhysician
              : "-"}
          </Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">
            Are there any other specialists working with this member
          </Label>
          <Text style={{ overflowWrap: "break-word" }}>
            {data?.specialistName ? data.specialistName : "-"}
          </Text>
        </StyledGridCol>

        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Specialist Name(s)</Label>
          <Text>{data?.provideName ? data.provideName : "-"}</Text>
        </StyledGridCol>
      </StyledGrid>
      <StyledGrid section>
        <StyledGridCol span={12}>
          <Heading offset={4}>Referral Information</Heading>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">Reason for referral</Label>
          <Text>{data?.reasonOfReferral ? data.reasonOfReferral : "-"}</Text>
        </StyledGridCol>
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}
          paddingYZero
          wordWrap
        >
          <Label size="md">
            Is the member aware and agreeable to this referral?
          </Label>
          <Text>
            {data?.memberAgreementForRefDesc
              ? data.memberAgreementForRefDesc
              : "-"}
          </Text>
        </StyledGridCol>
      </StyledGrid>
    </StyledGrid>
  );
};

export default KansasProviderReferralConfirmation;
