import React, { useEffect, useState } from "react";
import { DataTable } from "@abyss/web/ui/DataTable";
import { useDataTable } from "@abyss/web/hooks/useDataTable";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { useLocation, useNavigate } from "react-router-dom";
import { getData } from "./utils";
import { saveAs } from "file-saver";
import axios from "axios";
import useDocumentTitle from "../Forms/utils/useDocumentTitle";
import { LoadingOverlay } from "@abyss/web/ui/LoadingOverlay";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { Flex } from "@abyss/web/ui/Flex";
import PrintButton from "../Forms/utils/PrintButton";

export default function UhcWestReportCodeResults() {
  useDocumentTitle("UnitedHealthcare West Plan Schedules & Codes");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Fetching your report(s)"
  );
  const location = useLocation();
  const [api] = useState(location?.state?.api);
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "Report Name",
        accessor: "report_name",
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        canReorderColumn: false,
      },
      {
        Header: "Report Date",
        accessor: "report_date",
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        canReorderColumn: false,
      },
      {
        Header: "Report Timeframe",
        accessor: "report_timeframe",
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        canReorderColumn: false,
      },
      {
        Header: "Document Type",
        accessor: "document_type",
        canToggleVisibilty: true,
        isHiddenByDefault: false,
        canReorderColumn: false,
      },
    ],
    []
  );

  const getDocuments = async () => {
    try {
      const data = await fetch(api, {
        headers: { "Content-Type": "application/json" },
      });
      const res = await data.json();
      setTableData(getData(res));
      dataTableProps.setData(getData(res));
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    setLoadingMessage("Downloading your report(s)");
  };

  useEffect(() => {
    if (!api) {
      navigate("/uhcWestReportSearch");
    }
    if (api) {
      setIsLoading(true);
      getDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataTableProps = useDataTable({
    initialData: tableData,
    initialColumns: columns,
    showColumnVisibilityColumns: true,
    showPagination: true,
    onColumnVisibilityClose: (columns) => console.log(columns),
    onColumnVisibilityOpen: (columns) => console.log(columns),
    showSelection: true,
    showGlobalFilter: true,
    manualGlobalFilter: true,
    showFilterDataset: true,
    showColumnVisibilityConfig: true,
    showDownloadButton: true,
    downloadButtonConfig: {
      removeFiltered: true,
      removeFull: true,
      custom: {
        title: "ZIP",
        icon: <IconMaterial icon="folder_zip" />,
        onClick: async () => {
          setIsLoading(true);
          const selectedRowId = Object.keys(
            dataTableProps.state.selectedRowIds
          );
          if (selectedRowId.length > 0) {
            const payload = selectedRowId.map((id) => ({
              spaceId: tableData[Number(id)].spaceId,
              attachmentId: tableData[Number(id)].attachment.attachmentId,
              documentId: tableData[Number(id)].documentId,
            }));
            const { data, headers } = await axios({
              url: "/api/uhcwest/reportpackage",
              responseType: "blob",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
              },
              data: JSON.stringify([...payload]),
            });
            const headerLine = headers["content-disposition"];
            const filename = headerLine.replace(/[\w; ]+filename=/g, "");
            saveAs(data, filename);
          }

          setIsLoading(false);
        },
      },
    },
  });
  return (
    <LoadingOverlay
      loadingTitle="Please wait"
      loadingMessage={loadingMessage}
      isLoading={isLoading}
      isOpen={isLoading}
    >
      <Flex direction="row" style={{ padding: "16px" }}>
        <Heading offset={2}>
          {" "}
          UnitedHealthcare West Plan Schedules & Codes
        </Heading>
        <Flex
          direction="column"
          justify="center"
          alignItems="center"
          style={{ marginLeft: "auto" }}
        >
          <PrintButton title="Print Report" />
        </Flex>
      </Flex>
      <DataTable tableState={dataTableProps} hideTitleHeader />
    </LoadingOverlay>
  );
}
