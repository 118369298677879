import React from "react";
import { Heading } from "@abyss/web/ui/Heading/Heading";
import { Text } from "@abyss/web/ui/Text";
import { TextInput } from "@abyss/web/ui/TextInput";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useForm } from "@abyss/web/hooks/useForm/useForm";
import { useNavigate } from "react-router-dom";
import { useToast } from "@abyss/web/hooks/useToast/useToast";
import { FormProvider } from "@abyss/web/ui/FormProvider";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { RadioGroup } from "@abyss/web/ui/RadioGroup";
import { TextInputArea } from "@abyss/web/ui/TextInputArea";
import { Button } from "@abyss/web/ui/Button";
import axios from "axios";
import { StyledGrid, StyledGridCol } from "../utils/StyledComponents";
import PrintButton from "../utils/PrintButton";
import {
  alphaNumericValidation,
  alphaNumericWithoutSpace,
  alphaValidation,
} from "../utils/ValidationPatterns";

const KansasReferalForm = () => {
  useDocumentTitle("Kansas Provider Referral Form");
  const navigate = useNavigate();
  const { toast } = useToast();
  const defaultValues = {
    referringProName: "",
    prvTaxId: "",
    prvNPI: "",
    contactName: "",
    refPrvContactPhone: "",
    memberName: "",
    memberMedicaidId: "",
    diagnoseOrSignificantInfo: "",
    provideName: "",
    memberPrimaryCarePhysician: "",
    specialistName: "",
    reasonOfReferral: "",
    memberAgreementForRefDesc: "",
  };

  const REFERRAL_FORM_API = "/api/referralForm";
  const form = useForm(defaultValues);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
    };
    try {
      const resp = await axios({
        url: REFERRAL_FORM_API,
        method: "POST",
        data: { ...payload },
      });
      if (resp.status === 200) {
        toast.show({
          id: "success-toast",
          autoClose: 5000,
          withCloseButton: true,
          title: "Success",
          icon: <IconMaterial icon="check_circle" />,
          css: { "abyss-toast-container": { backgroundColor: "$success1" } },
          message: resp.data,
        });
        navigate("/kansasReferralConfirmation", {
          state: { data: { ...payload } },
        });
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((err) => {
          toast.show({
            id: err.field,
            withCloseButton: true,
            autoClose: 5000,
            title: `Validation Error: ${err.field}`,
            message: err.message,
            icon: <IconMaterial icon="error" />,
            css: { "abyss-toast-container": { backgroundColor: "$error3" } },
          });
        });
      }
    }
  };
  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <StyledGrid container>
        <StyledGrid section>
          <StyledGridCol span={11} rowGapSm paddingYZero>
            <Heading offset={2}>
              Provider Care Coordination Referral Form
            </Heading>
            <Text size="$sm">
              If you have a UHC Community Care KS Member who is in need of Care
              Coordination (Case Management), please provide the following
              information:
            </Text>
          </StyledGridCol>
          <PrintButton />
        </StyledGrid>
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Referring Provider Information</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="referringProName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required ",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
              subText="Person reporting issue on behalf of the user."
              descriptorsDisplay="column"
            />
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              mask="numeric"
              maxLength={9}
              label="Tax Identification Number"
              model="prvTaxId"
              isClearable
              validators={{
                required: "Tax Identification Number is required",
                minLength: {
                  value: 9,
                  message: "Tax Identification Number should have 9 digits",
                },
                maxLength: {
                  value: 9,
                  message: "Tax Identification Number should have 9 digits",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="NPI"
              mask="numeric"
              maxLength={10}
              model="prvNPI"
              isClearable
              validators={{
                required: "NPI is required",
                minLength: {
                  value: 10,
                  message: "NPI should have 10 digits",
                },
                maxLength: {
                  value: 10,
                  messsage: "NPI should be 10 digits",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>
        <StyledGrid section>
          <StyledGridCol paddingYZero span={12}>
            <Heading offset={4}>Referring Provider Contact Information</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="contactName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
              subText="Person who will be able to speak with us about this referral"
              descriptorsDisplay="column"
            />
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              mask="phone"
              label="Phone"
              model="refPrvContactPhone"
              isClearable
              inputLeftElement={
                <IconMaterial icon="phone" size="18px" color="$gray6" />
              }
              validators={{
                required: "Phone is required",
                validate: (v) => {
                  const value = v;
                  const phoneNumber = value.replace(/\D/g, "");
                  if (phoneNumber && phoneNumber.length < 10) {
                    return "Phone should have 10 digits";
                  }
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>
        <StyledGrid section>
          <StyledGridCol paddingYZero span={12}>
            <Heading offset={4}>Member Information</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Full Name"
              model="memberName"
              isClearable
              maxLength={95}
              validators={{
                required: "Full Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Full Name should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Medicaid ID"
              model="memberMedicaidId"
              maxLength={30}
              isClearable
              validators={{
                required: "Medicaid ID is required",
                pattern: {
                  value: alphaNumericWithoutSpace,
                  message: "Medicaid ID should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          ></StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            paddingYZero
          >
            <TextInput
              label="Diagnosis or Significant Present Problems"
              model="diagnoseOrSignificantInfo"
              maxLength={95}
              isClearable
              validators={{
                required:
                  "Diagnosis or Significant Present Problems is required",
                pattern: {
                  value: alphaNumericValidation,
                  message:
                    "Diagnosis or Significant Present Problems should not contain special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Primary Care Physician"
              model="memberPrimaryCarePhysician"
              maxLength={95}
              isClearable
              validators={{
                required: "Primary care physician is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Primary care physician should not contain numeric or special characters",
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
            paddingYZero
          >
            <RadioGroup
              label="Are there any other specialists working with this member?"
              model="specialistName"
              display="row"
              validators={{ required: "Please select a value" }}
              descriptorsDisplay="column"
            >
              <RadioGroup.Radio label="Yes" value="Yes" />
              <RadioGroup.Radio label="No" value="No" />
            </RadioGroup>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            paddingYZero
          >
            <TextInput
              label="Specialist name(s)"
              model="provideName"
              maxLength={95}
              validators={{
                required:
                  form.getValues("specialistName") === "Yes"
                    ? "Specialist name(s) are required"
                    : false,
                pattern: {
                  value: alphaValidation,
                  message:
                    "Specialist name(s) should not contain numeric or special characters",
                },
                validate: (v) => {
                  const specialistName = form.getValues("specialistName");
                  if (specialistName === "Yes" && !v) {
                    return "Specialist name(s) are Required";
                  }
                },
              }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>
        </StyledGrid>
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}>Referral Information</Heading>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            paddingYZero
          >
            <TextInputArea
              style={{ marginTop: "12px" }}
              label="Reason for Referral"
              maxLength={200}
              model="reasonOfReferral"
              displayMaxLength
              disableAutoSize
              validators={{ required: "Reason for referral is required" }}
              descriptorsDisplay="column"
            />
          </StyledGridCol>

          <StyledGridCol span={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
            <RadioGroup
              label="Is the member aware and agreeable to this referral?"
              model="memberAgreementForRefDesc"
              validators={{ required: "Member Agreement is required" }}
              display="row"
              descriptorsDisplay="column"
            >
              <RadioGroup.Radio label="Yes" value="Yes" />
              <RadioGroup.Radio label="No" value="No" />
            </RadioGroup>
          </StyledGridCol>
        </StyledGrid>
        <StyledGridCol style={{ width: "100px" }} paddingXZero paddingYZero>
          <Button size="$lg" type="submit">
            Submit
          </Button>
        </StyledGridCol>
      </StyledGrid>
    </FormProvider>
  );
};

export default KansasReferalForm;
