import React from "react";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import { useForm } from "@abyss/web/hooks/useForm";
import { FormProvider } from "@abyss/web/ui/FormProvider";
import { SelectInput } from "@abyss/web/ui/SelectInput";
import { TextInput } from "@abyss/web/ui/TextInput";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { TextInputArea } from "@abyss/web/ui/TextInputArea";
import { Button } from "@abyss/web/ui/Button";
import { DateInput } from "@abyss/web/ui/DateInput";
import { stateOptions } from "../utils/stateOptions";
import axios from "axios";
import { useToast } from "@abyss/web/hooks/useToast";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../utils/useDocumentTitle";
import { StyledGrid, StyledGridCol } from "../utils/StyledComponents";
import PrintButton from "../utils/PrintButton";
import {
  alphaValidation,
  alphaNumericValidation,
  alphaNumericWithoutSpace,
} from "../utils/ValidationPatterns";

const KansasProviderAppealForm = () => {
  useDocumentTitle("Kansas Provider Appeal Form");
  const navigate = useNavigate();
  const { toast } = useToast();
  const defaultValues = {
    servicingPrvName: "",
    servicingPrvTaxId: "",
    servicingPrvNPI: "",
    contactName: "",
    contactStreet: "",
    contactCity: "",
    contactState: "",
    contactZip: "",
    contactPhone: "",
    memberName: "",
    memberMedicaidId: "",
    memberSSN: "",
    codeDesc: "",
    denialDate: "",
    reasonOfAppeal: "",
  };

  const form = useForm(defaultValues);
  const APPEAL_FORM_API = "/api/appealForm";
  const currentDate = new Date();

  const onSubmit = async (data) => {
    try {
      const resp = await axios({
        url: APPEAL_FORM_API,
        method: "POST",
        data,
      });
      if (resp.status === 200) {
        toast.show({
          id: "success-toast",
          autoClose: 5000,
          withCloseButton: true,
          title: "Success",
          icon: <IconMaterial icon="check_circle" />,
          css: { "abyss-toast-container": { backgroundColor: "$success1" } },
          message: resp.data,
        });
        navigate("/kansasProviderAppealConfirmation", {
          state: { data },
        });
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((err) => {
          toast.show({
            id: err.field,
            withCloseButton: true,
            autoClose: 5000,
            title: `Validation Error: ${err.field}`,
            message: err.message,
            icon: <IconMaterial icon="error" />,
            css: { "abyss-toast-container": { backgroundColor: "$error3" } },
          });
        });
      }
    }
  };

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <StyledGrid container>
        <StyledGrid section>
          <StyledGridCol span={11} rowGapSm paddingYZero>
            <Heading offset={2}>Kansas Provider Appeal Form</Heading>
            <Text size="$sm">
              Claim Payment Appeals should be filed under Claims & Payment in
              the UnitedHealthcare Provider Portal. If your appeal is not claim
              related, please complete this form.
            </Text>
          </StyledGridCol>
          <PrintButton />
        </StyledGrid>
        {/* Servicing Provider Info */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Servicing Provider Information</Heading>
          </StyledGridCol>
          {/* Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="servicingPrvName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
            />
          </StyledGridCol>
          {/* Tax ID */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Tax Identification Number"
              model="servicingPrvTaxId"
              mask="numeric"
              isClearable
              maxLength={9}
              validators={{
                required: "Tax Identification Number is required",
                minLength: {
                  value: 9,
                  message: "Tax Identification Number should have 9 digits",
                },
              }}
            />
          </StyledGridCol>
          {/* NPI */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="NPI"
              model="servicingPrvNPI"
              mask="numeric"
              isClearable
              maxLength={10}
              validators={{
                required: "NPI is required",
                minLength: {
                  value: 10,
                  message: "NPI should have 10 digits",
                },
              }}
            />
          </StyledGridCol>
          {/* Auth ID */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            paddingYZero
          >
            <TextInput
              label="Authorization or Notification ID Number located on Adverse Benefit Determination Letter"
              model="authId"
              isClearable
              maxLength={30}
              validators={{
                required: "Authorization or Notification ID Number is required",
                pattern: {
                  value: alphaNumericValidation,
                  message:
                    "Authorization or Notification ID Number should not contain special characters",
                },
              }}
            />
          </StyledGridCol>
        </StyledGrid>
        {/* Contact Info */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Contact Information</Heading>
          </StyledGridCol>
          {/* Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="contactName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
              subText="Person reporting issue on behalf of the user."
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
            paddingYZero
          ></StyledGridCol>

          {/* Street Address */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Street Address"
              model="contactStreet"
              isClearable
              maxLength={95}
              validators={{ required: "Street Address is required" }}
            />
          </StyledGridCol>
          {/* City */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="City"
              model="contactCity"
              isClearable
              maxLength={95}
              validators={{ required: "City is required" }}
            />
          </StyledGridCol>
          {/* State */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <SelectInput
              label="State"
              model="contactState"
              isClearable
              validators={{ required: "State is required" }}
              options={stateOptions}
            />
          </StyledGridCol>
          {/* Zip Code */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Zip"
              model="contactZip"
              mask="zip"
              isClearable
              validators={{
                required: "Zip is required",
                validate: (v) => {
                  const value = v;
                  const zip = value.replace(/\D/g, "");
                  if (zip.length < 5) {
                    return "Zip should have 5 digits";
                  }
                },
              }}
            />
          </StyledGridCol>
          {/* Phone */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Phone"
              model="contactPhone"
              mask="phone"
              isClearable
              inputLeftElement={
                <IconMaterial icon="phone" size="18px" color="$gray6" />
              }
              validators={{
                required: "Phone is required",
                validate: (v) => {
                  const value = v;
                  const phoneNumber = value.replace(/\D/g, "");
                  if (phoneNumber.length < 10) {
                    return "Phone should have 10 digits";
                  }
                },
              }}
            />
          </StyledGridCol>
        </StyledGrid>
        {/* Member Info */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Member Information</Heading>
          </StyledGridCol>
          {/* Full Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Full Name"
              model="memberName"
              isClearable
              maxLength={95}
              validators={{
                required: "Full Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Full Name should not contain numeric or special characters",
                },
              }}
            />
          </StyledGridCol>
          {/* Medicaid ID */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Medicaid ID"
              model="memberMedicaidId"
              isClearable
              maxLength={20}
              validators={{
                required: "Medicaid ID is required",
                pattern: {
                  value: alphaNumericWithoutSpace,
                  message: "Medicaid ID should not contain special characters",
                },
              }}
            />
          </StyledGridCol>
          {/* SSN */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Social Security Number"
              model="memberSSN"
              mask="ssn"
              isClearable
              validators={{
                required: "Social Security Number is required",
                validate: (v) => {
                  const value = v;
                  const ssn = value.replace(/\D/g, "");
                  if (ssn.length < 9) {
                    return "Social Security Number should have 9 digits";
                  }
                },
              }}
            />
          </StyledGridCol>
        </StyledGrid>
        {/* Issue Details */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Issue Details</Heading>
          </StyledGridCol>
          {/* desc of code */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            paddingYZero
          >
            <TextInputArea
              style={{ marginTop: "12px" }}
              label="If Service denial or Denial of Prior Authorization, which CPT or HCPCS Code was denied? Please include Description of Code"
              maxLength={200}
              model="codeDesc"
              displayMaxLength
              subText="Include the rejection message if available."
              disableAutoSize
            />
          </StyledGridCol>
          {/* Date of deniel of Service */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <DateInput
              model="denialDate"
              label="Date of Denial of Service"
              maximumDate={currentDate}
              placeholder=""
              subText="Date Format: MM/DD/YYYY"
              validators={{ required: "Date of Denial of Service is required" }}
            />
          </StyledGridCol>
          {/* Appeal Reason */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            paddingYZero
          >
            <TextInputArea
              style={{ marginTop: "12px" }}
              label="Narrative of Appeal Reason"
              maxLength={200}
              model="reasonOfAppeal"
              displayMaxLength
              subText="Include the rejection message if available."
              disableAutoSize
              validators={{
                required: "Narrative of Appeal Reason is required",
              }}
            />
          </StyledGridCol>
        </StyledGrid>
        {/* Submit Button */}
        <StyledGridCol style={{ width: "100px" }} paddingXZero paddingYZero>
          <Button size="$lg" type="submit">
            Submit
          </Button>
        </StyledGridCol>
      </StyledGrid>
    </FormProvider>
  );
};

export default KansasProviderAppealForm;
