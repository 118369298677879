import React from 'react';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';

const LoadingSpinner =(props) => {
    return (
        <LoadingOverlay
        loadingTitle='Please Wait'
        loadingMessage='while we submit the Indiana hospice notification form.'
        width='25%'
        isLoading={props.isLoading}>
        {props.children}
        </LoadingOverlay>
    );
}

export default LoadingSpinner;