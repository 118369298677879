import React from "react";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import { useForm } from "@abyss/web/hooks/useForm";
import { FormProvider } from "@abyss/web/ui/FormProvider";
import { TextInput } from "@abyss/web/ui/TextInput";
import { IconMaterial } from "@abyss/web/ui/IconMaterial";
import { TextInputArea } from "@abyss/web/ui/TextInputArea";
import { Button } from "@abyss/web/ui/Button";
import { DateInput } from "@abyss/web/ui/DateInput";
import { Checkbox } from "@abyss/web/ui/Checkbox";
import { CheckboxGroup } from "@abyss/web/ui/CheckboxGroup";
import { SelectInput } from "@abyss/web/ui/SelectInput";
import axios from "axios";
import { useToast } from "@abyss/web/hooks/useToast";
import { stateOptions } from "../utils/stateOptions";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../utils/useDocumentTitle";
import { StyledGrid, StyledGridCol } from "../utils/StyledComponents";
import PrintButton from "../utils/PrintButton";
import {
  alphaNumericWithoutSpace,
  alphaValidation,
} from "../utils/ValidationPatterns";

const KansasProviderGrievanceForm = () => {
  useDocumentTitle("Kansas Provider Grievance Form");
  const navigate = useNavigate();
  const { toast } = useToast();
  const defaultValues = {
    prvName: "",
    prvTaxId: "",
    prvNPI: "",
    contactName: "",
    contactStreet: "",
    contactCity: "'",
    contactState: "",
    contactZip: "",
    contactPhone: "",
    memberMedicaidId: "",
    memberName: "",
    memberSSN: "",
    categoryOfGrievance: "",
    categoryOfGrievanceOther: "",
    incidentDate: "",
    descOfGrievance: "",
  };

  const form = useForm(defaultValues);
  const GRIEVANCE_FORM_API = "/api/grievanceForm";
  const currentDate = new Date();

  const onSubmit = async (data) => {
    try {
      const resp = await axios({
        url: GRIEVANCE_FORM_API,
        method: "POST",
        data: {
          ...data,
          categoryOfGrievance:
            data.categoryOfGrievance.length > 0
              ? data.categoryOfGrievance.join(",")
              : "",
        },
      });
      if (resp.status === 200) {
        toast.show({
          id: "success-toast",
          autoClose: 5000,
          withCloseButton: true,
          title: "Success",
          icon: <IconMaterial icon="check_circle" />,
          css: { "abyss-toast-container": { backgroundColor: "$success1" } },
          message: resp.data,
        });
        navigate("/kansasProviderGrievanceConfirmation", {
          state: { data },
        });
      }
    } catch (error) {
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach((err) => {
          toast.show({
            id: err.field,
            withCloseButton: true,
            autoClose: 5000,
            title: `Validation Error: ${err.field}`,
            message: err.message,
            icon: <IconMaterial icon="error" />,
            css: { "abyss-toast-container": { backgroundColor: "$error3" } },
          });
        });
      }
    }
  };

  return (
    <FormProvider state={form} onSubmit={onSubmit}>
      <StyledGrid container>
        <StyledGrid section>
          <StyledGridCol span={11} rowGapSm paddingYZero>
            <Heading offset={2}>Kansas Provider Grievance Form</Heading>
            <Text size="$sm">
              A Grievance is defined as an expression of dissatisfaction about
              any matter other than an Adverse Benefit Determination or an
              Action. Possible subjects for Grievances include, but are not
              limited to, the quality of care or services provided and aspects
              of interpersonal relationships such as rudeness of an employee,
              failure of an employee to respond to inquiries, or failure to
              respect the Member's rights regardless of whether remedial action
              is requested.
            </Text>
          </StyledGridCol>
          <PrintButton />
        </StyledGrid>

        {/*  Servicing provider info*/}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Servicing Provider Information</Heading>
          </StyledGridCol>
          {/* Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="prvName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    " Name should not contain numeric or special characters",
                },
              }}
              subText="Person reporting issue on behalf of the user."
            />
          </StyledGridCol>
          {/* Tax ID */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Tax Identification Number"
              model="prvTaxId"
              mask="numeric"
              isClearable
              maxLength={9}
              validators={{
                required: "Tax Identification Number is required",
                minLength: {
                  value: 9,
                  message: "Tax Identification Number should have 9 digits",
                },
              }}
            />
          </StyledGridCol>
          {/* NPI */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="NPI"
              model="prvNPI"
              mask="numeric"
              isClearable
              maxLength={10}
              validators={{
                required: "NPI is required",
                minLength: {
                  value: 10,
                  message: "NPI should have 10 digits",
                },
              }}
            />
          </StyledGridCol>
        </StyledGrid>

        {/* Contact Information */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Contact Information</Heading>
          </StyledGridCol>
          {/* Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Name"
              model="contactName"
              isClearable
              maxLength={95}
              validators={{
                required: "Name is required",
                pattern: {
                  value: alphaValidation,
                  message:
                    "Name should not contain numeric or special characters",
                },
              }}
              subText="Person who will be able to speak with us about this grievance."
            />
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
            paddingYZero
          ></StyledGridCol>
          {/* street Address */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Street Address"
              model="contactStreet"
              maxLength={95}
              isClearable
              validators={{ required: "Street Address is required" }}
            />
          </StyledGridCol>
          {/* City */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="City"
              model="contactCity"
              isClearable
              maxLength={95}
              validators={{ required: "City is required" }}
            />
          </StyledGridCol>
          {/* State */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <SelectInput
              label="State"
              model="contactState"
              isClearable
              validators={{ required: "State is required" }}
              options={stateOptions}
            />
          </StyledGridCol>
          {/* Zip Code */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Zip"
              model="contactZip"
              mask="zip"
              isClearable
              validators={{
                required: "Zip is required",
                validate: (v) => {
                  const value = v;
                  const zip = value.replace(/\D/g, "");
                  if (zip.length < 5) {
                    return "Zip should have 5 digits";
                  }
                },
              }}
            />
          </StyledGridCol>
          {/* Phone */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Phone"
              model="contactPhone"
              mask="phone"
              isClearable
              inputLeftElement={
                <IconMaterial icon="phone" size="18px" color="$gray6" />
              }
              validators={{
                required: "Phone is required",
                validate: (v) => {
                  const value = v;
                  const phoneNumber = value.replace(/\D/g, "");
                  if (phoneNumber.length < 10) {
                    return "Phone should have 10 digits";
                  }
                },
              }}
            />
          </StyledGridCol>
        </StyledGrid>

        {/* Member Information */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Member Information</Heading>
          </StyledGridCol>

          {/* Full Name */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Full Name"
              model="memberName"
              isClearable
              maxLength={95}
              validators={{
                pattern: {
                  value: alphaValidation,
                  message:
                    "Full Name should not contain numeric or special characters",
                },
              }}
            />
          </StyledGridCol>
          {/* Medicaid ID */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Medicaid ID"
              model="memberMedicaidId"
              isClearable
              maxLength={20}
              validators={{
                pattern: {
                  value: alphaNumericWithoutSpace,
                  message: "Medicaid ID should not contain special characters",
                },
              }}
            />
          </StyledGridCol>
          {/* SSN */}
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
            paddingYZero
          >
            <TextInput
              label="Social Security Number"
              model="memberSSN"
              mask="ssn"
              isClearable
              validators={{
                validate: (v) => {
                  const value = v;
                  const ssn = value.replace(/\D/g, "");
                  if (v && ssn.length < 9) {
                    return "Social Security Number should have 9 digits";
                  }
                },
              }}
            />
          </StyledGridCol>
        </StyledGrid>

        {/* Grievance Details */}
        <StyledGrid section>
          <StyledGridCol span={12} paddingYZero>
            <Heading offset={4}> Grievance Details</Heading>
          </StyledGridCol>
        </StyledGrid>
        {/* Catergory of Grieance */}
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          paddingYZero
        >
          <CheckboxGroup
            label="Category of Grievance"
            model="categoryOfGrievance"
          >
            <Checkbox label="Rights/Dignity" value="Rights/Dignity" />
            <Checkbox label="Access to Services" value="Access to Services" />
            <Checkbox label="Billing or Benefits" value="Billing or Benefits" />
            <Checkbox label="Quality of Care" value="Quality of Care" />
            <Checkbox label="Other (Specify)" value="Other (Specify)" />
          </CheckboxGroup>
          <TextInput
            model="categoryOfGrievanceOther"
            isClearable
            maxLength={200}
            validators={{
              validate: (v) => {
                const categoryOfGrievance = form.getValues(
                  "categoryOfGrievance"
                );
                if (categoryOfGrievance.includes("Other (Specify)") && !v) {
                  return "Please describe the nature of your grievance.";
                }
              },
            }}
          />
        </StyledGridCol>
        {/* Date of Incident */}
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
          paddingYZero
        >
          <DateInput
            model="incidentDate"
            label="Date of Incident"
            placeholder=""
            subText="Date Format: MM/DD/YYYY"
            maximumDate={currentDate}
            validators={{ required: "Date of Incident is required" }}
          />
        </StyledGridCol>
        {/* Brief Description */}
        <StyledGridCol
          span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          paddingYZero
        >
          <TextInputArea
            style={{ marginTop: "12px" }}
            label="Brief Description of Grievance"
            maxLength={200}
            model="descOfGrievance"
            displayMaxLength
            disableAutoSize
            validators={{
              required: "Brief Description of Grievance is required",
            }}
          />
        </StyledGridCol>

        <StyledGridCol style={{ width: "100px" }} paddingXZero paddingYZero>
          <Button size="$lg" type="submit">
            Submit
          </Button>
        </StyledGridCol>
      </StyledGrid>
    </FormProvider>
  );
};

export default KansasProviderGrievanceForm;
