import React from "react";
import { styled } from "@abyss/web/tools/styled";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import useDocumentTitle from "../utils/useDocumentTitle";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
});
const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
});
export const KansasProviderConfirmation = () => {
  useDocumentTitle("Kansas Provider Confirmation");
  return (
    <Container style={{ rowGap: "24px" }}>
      <Row>
        <Heading>Confirmation and Summary</Heading>
      </Row>
      <Container style={{}}>
        <Row>
          <Text color="$primary1" fontWeight={700} size="$lg">
            {" "}
            Serviceing Provider Information
          </Text>
        </Row>
      </Container>
    </Container>
  );
};
