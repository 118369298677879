import React from "react";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import { styled } from "@abyss/web/tools/styled";
import { Label } from "@abyss/web/ui/Label";
import { Grid } from "@abyss/web/ui/Grid";
import { Alert } from "@abyss/web/ui/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useEffect } from "react";
import PrintButton from "../utils/PrintButton";

const Container = styled(Grid, {
  padding: "30px 50px",
  marginLeft: "20px",
});

const styles = {
  paddingLeftZero: {
    paddingLeft: "0px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  marginY12: {
    marginTop: "12px",
    marginBottom: "12px",
  },
  outerStyles: {
    paddingTop: "6px",
    paddingBottom: "6px",
  },
};

const StyledGridCol = styled(Grid.Col, {});

export default function KansasProviderGrievanceConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state?.data;
  useEffect(() => {
    if (!data) {
      navigate("/kansasProviderGrievanceForm");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDocumentTitle("Kansas Provider Grievance Confirmation");

  return (
    <Container>
      <StyledGridCol span={11}>
        <Heading offset={0}>Confirmation and Summary</Heading>
      </StyledGridCol>

      <PrintButton confirmation />
      <StyledGridCol span={{ xs: 12, sm: 12, md: 11, lg: 11, xl: 11 }}>
        <Alert
          title="Kansas Provider Grievance Form Submission Complete"
          variant="success"
          actionText="Action"
          onClose={() => {
            console.log("Close Triggered");
          }}
        >
          <Grid space="$sm">
            <StyledGridCol span={{ xs: 4, sm: 4, md: 12, lg: 12, xl: 12 }}>
              A UnitedHealthcare Representative will be in contact with you,
              please allow up to 10 calendar days for response.
            </StyledGridCol>
          </Grid>
        </Alert>
      </StyledGridCol>
      <StyledGridCol span={12}>
        <Heading color="$primary1" offset={4}>
          Servicing Provider Information
        </Heading>
        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Name</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.prvName ? data.prvName : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Tax Identification Number</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {data?.prvTaxId ? data.prvTaxId : "-"}
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">NPI</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {data?.prvNPI ? data.prvNPI : "-"}
            </StyledGridCol>
          </StyledGridCol>
        </Grid>

        <Heading color="$primary1" offset={4}>
          Contact Information
        </Heading>
        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Name</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.contactName ? data.contactName : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
        </Grid>

        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Street Address</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.contactStreet ? data.contactStreet : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">City</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.contactCity ? data.contactCity : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">State</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {data?.contactState ? data.contactState : "-"}
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Zip</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text>{data?.contactZip ? data.contactZip : "-"}</Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Phone</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text>{data?.contactPhone ? data.contactPhone : "-"}</Text>
            </StyledGridCol>
          </StyledGridCol>
        </Grid>

        <Heading color="$primary1" offset={4}>
          Member Information
        </Heading>
        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Full Name</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.memberName ? data.memberName : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Medicaid ID</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {data?.memberMedicaidId ? data.memberMedicaidId : "-"}
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 3, lg: 3, xl: 3 }}
            style={styles.outerStyles}
          >
            <Label size="md">Social Security Number</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {data?.memberSSN ? data.memberSSN : "-"}
            </StyledGridCol>
          </StyledGridCol>
        </Grid>

        <Heading color="$primary1" offset={4}>
          Grievance Details
        </Heading>

        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 8, lg: 8, xl: 8 }}
            style={styles.outerStyles}
          >
            <Label size="md">Category of Grievance</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.categoryOfGrievance
                  ? data.categoryOfGrievance.join(",")
                  : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 8, lg: 8, xl: 8 }}
            style={styles.outerStyles}
          >
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.categoryOfGrievanceOther
                  ? data.categoryOfGrievanceOther
                  : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 8, lg: 8, xl: 8 }}
            style={styles.outerStyles}
          >
            <Label size="md">Date of Incident</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {data?.incidentDate ? data.incidentDate : "-"}
            </StyledGridCol>
          </StyledGridCol>

          <StyledGridCol
            span={{ xs: 12, md: 8, lg: 8, xl: 8 }}
            style={styles.outerStyles}
          >
            <Label size="md">Brief Description of Grievance</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.descOfGrievance ? data.descOfGrievance : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
        </Grid>
      </StyledGridCol>
    </Container>
  );
}
