import React, { useState } from "react";
import { Flex } from "@abyss/web/ui/Flex";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import { FormProvider } from "@abyss/web/ui/FormProvider";
import { SelectInput } from "@abyss/web/ui/SelectInput";
import { useForm } from "@abyss/web/hooks/useForm";
import { Button } from "@abyss/web/ui/Button";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../Forms/utils/useDocumentTitle";
import PrintButton from "../Forms/utils/PrintButton";

export default function UhcWestReportCode() {
  useDocumentTitle("UnitedHealthcare West Plan Schedules & Codes");
  const [selectedLOB, setSelectedLOB] = useState([]);

  const defaultValues = {
    state: "",
    lineOfBusiness: "",
  };
  const medicareAdvantage = [
    { label: "Medicare Advantage", value: "Medicare Advantage" },
  ];
  const bothOption = [
    { label: "Commercial", value: "Commercial" },
    { label: "Medicare Advantage", value: "Medicare Advantage" },
  ];

  const stateToLOBOptions = {
    AZ: medicareAdvantage,
    CA: bothOption,
    CO: medicareAdvantage,
    NV: medicareAdvantage,
    OK: bothOption,
    OR: bothOption,
    TX: bothOption,
    WA: bothOption,
  };

  const handleStateChange = (newState) => {
    form.setValue("lineOfBusiness", null);
    setSelectedLOB(stateToLOBOptions[newState]);
    if (newState && stateToLOBOptions[newState].length === 1) {
      form.setValue("lineOfBusiness", stateToLOBOptions[newState][0].value);
      form.clearErrors("lineOfBusiness");
    }
  };

  const navigate = useNavigate();
  const form = useForm(defaultValues);
  const UHC_WEST_REPORT_RESULTS = "/uhcWestReportResults";

  const onSubmit = (data) => {
    navigate(UHC_WEST_REPORT_RESULTS, {
      state: {
        api: `/api/uhcwest/documents?state=${data.state}&lob=${data.lineOfBusiness}&sort=desc`.toString(),
      },
    });
  };
  return (
    <Flex
      direction="column"
      style={{
        rowGap: "12px",
        colGap: "12px",
        padding: "24px 24px",
        minHeight: "750px",
      }}
    >
      <Flex direction="row">
        <Heading offset={2}>
          UnitedHealthcare West Plan Schedules & Codes
        </Heading>
        <Flex
          direction="column"
          justify="center"
          alignItems="center"
          style={{ marginLeft: "auto" }}
        >
          <PrintButton title="Print Page" />
        </Flex>
      </Flex>

      <Flex direction="column" style={{ gap: "24px" }}>
        <FormProvider state={form} onSubmit={onSubmit}>
          <Flex direction="column" style={{ rowGap: "16px" }}>
            <Flex direction="row">
              <Text color="$gray8" fontWeight={700} size="$md">
                All fields required.
              </Text>
            </Flex>

            <Flex
              direction="row"
              style={{ flexWrap: "nowrap", height: "80px" }}
            >
              <SelectInput
                label="State"
                model="state"
                width="25%"
                placeholder="Select State"
                isClearable
                options={[
                  { value: "AZ", label: "Arizona" },
                  { value: "CA", label: "California" },
                  { value: "CO", label: "Colorado" },
                  { value: "NV", label: "Nevada" },
                  { value: "OK", label: "Oklahoma" },
                  { value: "OR", label: "Oregon" },
                  { value: "TX", label: "Texas" },
                  { value: "WA", label: "Washington" },
                ]}
                onChange={handleStateChange}
                validators={{ required: "State is required" }}
              />
            </Flex>

            <Flex
              direction="row"
              width="80%"
              style={{ flexWrap: "nowrap", height: "80px" }}
            >
              <SelectInput
                width="25%"
                label="Line of Business"
                model="lineOfBusiness"
                placeholder="Select Business"
                isClearable
                isSearchable
                options={selectedLOB}
                validators={{ required: "Business is required" }}
              />
            </Flex>

            <Flex direction="row" width="40%">
              <Button type="submit">Search</Button>
            </Flex>
          </Flex>
        </FormProvider>
      </Flex>
    </Flex>
  );
}
