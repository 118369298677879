import React from "react";
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { StyledGrid,StyledGridCol } from "./StyledComponents";
import { styled } from '@abyss/web/tools/styled';

const StyledFileUpload = styled (FileUpload, {
  '&.abyss-file-upload-label':{color: '#002677 ! important', fontSize: '22.78px ! important'},
  },
)
const DocUpload = () => {
  return (
   <StyledGrid section>
   <StyledGridCol paddingYZero span= {12}>
   <StyledFileUpload
    label="File Upload"
        uploadMessage="Drag your PDF files here"
        model="files"
        maxFileSize={1}
        maxFiles={3}
        fileTypes={{'application/pdf': ['.pdf']}}
        validators={{required: "Please upload at least one file to continue."}}
      />
   </StyledGridCol>
   </StyledGrid>
  );
}

export default DocUpload;