/* eslint-disable no-useless-escape */
import dayjs from "dayjs";
import moment from "moment";

const getReportDate = (reportName, date) => {
  const names = reportName
    .trim()
    .replaceAll(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, " ")
    .split(" ");
  const numberReg = /[0-9]/;
  if (date) {
    return moment(date).format("MMM YYYY").toString().toUpperCase();
  } else if (numberReg.test(names[1])) {
    return `${names[0]} ${names[1]}`.toUpperCase();
  } else {
    return "";
  }
};

const getData = (data) => {
  const today = dayjs();
  const resp = data.documents.map(
    ({
      searchTerms,
      attachments,
      modifiedOn,
      createdOn,
      documentId,
      spaceId,
    }) => {
      const report_date = getReportDate(
        searchTerms.formDataDocumentName,
        searchTerms.formCenterDataLastUpdatedAt
      );
      const document_type = searchTerms.formDataDocumentName.split(".")[1];

      return {
        report_name: searchTerms.formDataDocumentName
          .replaceAll(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, " ")
          .toString()
          .toUpperCase(),
        report_date: report_date.toString().toUpperCase(),
        report_timeframe:
          today.diff(dayjs(createdOn), "month") > 0
            ? "Past Months"
            : "Current Month",
        document_type: document_type.toString().toUpperCase(),
        documentId: documentId,
        attachment: { ...attachments[0] },
        spaceId,
      };
    }
  );
  return resp;
};

export { getData };
