import React, { useEffect } from "react";
import { Heading } from "@abyss/web/ui/Heading";
import { Text } from "@abyss/web/ui/Text";
import { styled } from "@abyss/web/tools/styled";
import { Label } from "@abyss/web/ui/Label";
import { Grid } from "@abyss/web/ui/Grid";
import { Alert } from "@abyss/web/ui/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import PrintButton from "../utils/PrintButton";

const Container = styled(Grid, {
  padding: "24px 24px",
});

const styles = {
  paddingLeftZero: {
    paddingLeft: "0px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  marginY12: {
    marginTop: "12px",
    marginBottom: "12px",
  },
  outerStyles: {
    paddingTop: "6px",
    paddingBottom: "6px",
  },
};

const StyledGridCol = styled(Grid.Col, {});

export default function EDIConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const data = state?.data;
  const memberInfo = data?.memberInfo;
  const contactInfo = data?.contactInfo;
  window.history.replaceState({}, document.title);
  useEffect(() => {
    if (!data) {
      navigate("/ediSupport");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <StyledGridCol span={11}>
        <Heading offset={0}>Confirmation and Summary</Heading>
      </StyledGridCol>
      <PrintButton confirmation />
      <StyledGridCol span={{ xs: 12, sm: 12, md: 11, lg: 11, xl: 11 }}>
        <Alert
          title="EDI Transaction Support Form Submission Complete"
          variant="success"
        >
          <Grid space="$sm">
            <StyledGridCol span={{ xs: 4, sm: 4, md: 12, lg: 12, xl: 12 }}>
              A UnitedHealthcare Representative will be in contact with you.
            </StyledGridCol>
            <StyledGridCol span={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 8 }}>
              <b>Case Number: </b>
              <Text size="$sm">{data?.caseNumber}</Text>
            </StyledGridCol>
            <StyledGridCol span={{ xs: 6, sm: 6, md: 8, lg: 8, xl: 8 }}>
              <b>Contact Name: </b>
              <Text size="$sm" style={{ overflowWrap: "break-word" }}>
                {contactInfo?.contactName ? contactInfo.contactName : "-"}
              </Text>
            </StyledGridCol>
            <StyledGridCol span={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 8 }}>
              <b>Function: </b>
              <Text size="$sm">{data?.function ? data.function : "-"}</Text>
            </StyledGridCol>
          </Grid>
        </Alert>
      </StyledGridCol>
      <StyledGridCol span={12}>
        <Heading color="$primary1" offset={4}>
          Contact Information
        </Heading>
        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Contact Name (UHG Associate Contact)</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {contactInfo?.contactName ? contactInfo.contactName : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Role of Contact</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {contactInfo?.completedBy ? contactInfo.completedBy : "-"}
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Phone</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {contactInfo?.phoneNo ? contactInfo.phoneNo : "-"}
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Email</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {contactInfo?.email ? contactInfo.email : "-"}
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Tax Identification Number</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {contactInfo?.taxId ? contactInfo.taxId : "-"}
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Billing Provider NPI</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              {contactInfo?.billingProvNpi ? contactInfo.billingProvNpi : "-"}
            </StyledGridCol>
          </StyledGridCol>
        </Grid>

        <Heading color="$primary1" offset={4}>
          Issue Details
        </Heading>

        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Product</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.product ? data.product : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Function</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 6, lg: 8, xl: 8 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.function ? data.function : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            style={styles.outerStyles}
          >
            <Label size="md">Detailed explanation of the issue</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {data?.issueDesc ? data.issueDesc : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
        </Grid>

        <Heading color="$primary1" offset={4}>
          Additional Information
        </Heading>
        <Text
          size="$md"
          fontWeight={500}
          style={{
            lineHeight: "22px",
          }}
        >
          If the issue is impacting more than one member, provide only one
          member as an example.
        </Text>
        <Grid style={styles.marginY12}>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Member Name</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.memberName ? memberInfo.memberName : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Date of Service</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              {memberInfo?.dateOfService ? memberInfo.dateOfService : "-"}
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Date EDI Transaction was Submitted</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.dateEdiTxnSubmitted
                  ? memberInfo.dateEdiTxnSubmitted
                  : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Member ID</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.memberId ? memberInfo.memberId : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Billed Amount</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.billedAmt ? memberInfo.billedAmt : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Claim Number</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.claimNo ? memberInfo.claimNo : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Referral/Notification Number</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.refNotificationNo
                  ? memberInfo.refNotificationNo
                  : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Check/Payment Number</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.checkPaymentNo ? memberInfo.checkPaymentNo : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Payer ID</Label>

            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.payerId ? memberInfo.payerId : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Rendering Provider</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.renderingProv ? memberInfo.renderingProv : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Rendering Provider NPI</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.renderingProvNpi
                  ? memberInfo.renderingProvNpi
                  : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Clearinghouse</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.clearingHouse ? memberInfo.clearingHouse : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
          <StyledGridCol
            span={{ xs: 12, md: 6, lg: 4, xl: 4 }}
            style={styles.outerStyles}
          >
            <Label size="md">Payer Trace Number</Label>
            <StyledGridCol
              span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              style={styles.paddingLeftZero}
            >
              <Text style={{ overflowWrap: "break-word" }}>
                {memberInfo?.payerTraceNo ? memberInfo.payerTraceNo : "-"}
              </Text>
            </StyledGridCol>
          </StyledGridCol>
        </Grid>
      </StyledGridCol>
    </Container>
  );
}
