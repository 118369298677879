export const OIDC_CONFIG = {
  automaticSilentRenew: true,
  checkSessionIntervalInSeconds: 50,
  metadata: {
    version: "1.0",
    issuer: process.env.REACT_APP_AUTHORITY,
    authorization_endpoint: process.env.REACT_APP_AUTHORITY + "/oidc/authorize",
    token_endpoint: process.env.REACT_APP_AUTHORITY + "/oidc/token",
    userinfo_endpoint: process.env.REACT_APP_AUTHORITY + "/oidc/userinfo",
    introspection_endpoint:
      process.env.REACT_APP_AUTHORITY + "/oidc/token/introspect",
    revocation_endpoint: process.env.REACT_APP_AUTHORITY + "/oidc/token/revoke",
    jwks_uri: process.env.REACT_APP_AUTHORITY + "/oidc/jwks",
    end_session_endpoint: process.env.REACT_APP_AUTHORITY + "/oidc/logout",
  },
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  acr_values: "urn:aikyam:aal:mfa",
  response_type: "code",
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  scope: "openid profile email address phone",
  revokeTokensOnSignout: true,
  onSigninCallback: (user) => {
    window.history.replaceState(
      { prev: localStorage.getItem("prev") },
      document.title,
      "/#" + localStorage.getItem("prev")
    );
    localStorage.removeItem("prev");
    window.location.assign(window.location.href);
  },
};
